import { motion } from "framer-motion";
import * as React from "react"
import CDLogo from "../components/CDLogo";
import ProgressBar from "../components/ProgressBar";
import Twitter from "../components/Twitter";
import SEO from "../components/SEO";
import FooterTrigger from "../components/FooterTrigger";


import "../styles/bootstrap-custom.scss";

const IndexPage = () => {
  return (
    <div className="root">
      
      <main className="content-container">
        
        <SEO title="Crimson Dose"/>

        <div className="container pb-5">
            <div className="row">

                <div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2 text-start mt-5">
                  <div className="text-center">
                    <CDLogo />
                  </div>
                </div>

                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12 text-start">
                    
                    <h1 className="mt-5 mb-4">Crimson Dose</h1>

                    <p>
                        <a href="https://en.wikipedia.org/wiki/Red_pill_and_blue_pill" target="_blank">Crimson Dose</a> is a moniker I use to help anchor my family in a new reality.
                    </p>
                    <p>
                      It's a reality where we strive for financial independence and control of our own future.
                    </p>
                    <p>
                        We have trashed the systems that prey on ignorance and consume time and energy for nothing in return.
                    </p>
                    <p>
                        We are building habits and feedback loops that make us feel at home in our debt free skin.
                    </p>
                    

                    <h2 className="mt-5 mb-3">Articles</h2>

                    <p className="mt-4">
                        Here to provide personal finance education and put social media's financial advice to the test. I'll be using homegrown software to generate reports and author articles.
                    </p>

                    <p>Coming soon...</p>

                    <h2 className="mt-5 mb-4">Sharing My Journey</h2>

                    <ul className="cd-ul mt-4">
                      <li>
                        My wife and I are older millenials.
                      </li>
                      <li>
                        I do software. My wife does medicine. 
                      </li>
                      <li>
                        We have two younger kids.
                      </li>
                      <li>
                        We want generational financial independence.
                      </li>
                    </ul>
                    
                    <h3 className="mt-5 mb-3">Salaries</h3>

                    <p className="mt-4">
                        We first focussed heavily on increasing the salaries of our professional careers. 
                    </p>

                    <p className="mt-4">Professional development, market research, and some employer switches contributed to the increases.</p>

                    <div className="row" style={{overflow: "hidden"}}>
                      
                      <motion.div
                        className="col-xl-3 col-lg-4 col-6 mt-3"
                        initial={{ x: 30, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.25, duration: 0.75 }}
                      >
                        <div className="card metrics-card">
                          <div className="card-body">
                            <span className="money-font times-font">2.3x</span>
                          </div>
                          <div className="card-footer">
                            <span className="">my salary</span>
                          </div>
                        </div>
                      </motion.div>

                      <motion.div
                        className="col-xl-3 col-lg-4 col-6 mt-3"
                        initial={{ x: 30, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.25, duration: 0.75 }}
                      >
                        <div className="card metrics-card">
                          <div className="card-body">
                            <span className="money-font times-font">2.5x</span>
                          </div>
                          <div className="card-footer">
                            <span className="">wife's salary</span>
                          </div>
                        </div>
                      </motion.div>

                    </div>

                    <p className="mt-4">
                      Our salaries are above market value so the juice to squeeze ratio is starting to even out. 
                    </p>

                    <p className="mt-4">We still continue focus on skills developement. Self-employment might be an option after the kids are in school.</p>


                    <h3 className="mt-5 mb-3">Debt</h3>

                    <p>
                      In 2017 we focused on debt. As of 2021 we paid off all of our <span className="money-font-bold">$410K</span> debt!
                    </p>
  
                    <div className="mt-3 money-label-font-large">Student Loans - <span className="money-font-large">$325,000</span> <span className="float-end">100%</span></div>
                    <ProgressBar percent={100}/>
                    
                    <div className="mt-3 money-label-font-large">Car Loans - <span className="money-font-large">$70,000</span> <span className="float-end">100%</span></div>
                    <ProgressBar percent={100}/>
                    
                    <div className="mt-3 money-label-font-large">Family Loan - <span className="money-font-large">$15,000</span> <span className="float-end">100%</span></div>
                    <ProgressBar percent={100}/>


                    <h3 className="mt-5 mb-3">Emergency Fund</h3>

                    <p>Having cash on hand for unexpected events has given us great peace of mind.</p>

                    <div className="mt-2 money-label-font-large">Six Months of Expenses <span className="float-end">100%</span></div>
                    <ProgressBar percent={100}/>

                    <h3 className="mt-5 mb-3">Retirement Accounts</h3>

                    <p>
                        We got more serious about investing in retirement accounts in 2017. We wish we would have started a LOT earlier.
                    </p>

                    <div className="mt-3 money-label-font-large">My HSA <span className="float-end">Maxed & Invested</span></div>
                    <ProgressBar percent={100}/>

                    <div className="mt-3 money-label-font-large">Wife 401K <span className="float-end">Maxed</span></div>
                    <ProgressBar percent={100}/>

                    <div className="mt-3 money-label-font-large">Wife IRA <span className="float-end">Maxed</span></div>
                    <ProgressBar percent={100}/>

                    <div className="mt-3 money-label-font-large">My 401K <span className="float-end">80%</span></div>
                    <ProgressBar percent={80}/>

                    <div className="mt-3 money-label-font-large">My IRA <span className="float-end">0%</span></div>
                    <ProgressBar percent={5}/>

                    <div className="mt-3 money-label-font-large">Wife HSA <span className="float-end">0%</span></div>
                    <ProgressBar percent={5}/>

                    <p className="mt-4">We made a dent in tax advantaged vehicles. I need to max out my 401k personal contribution and my personal IRA. We will consider maxing an HSA for my wife.</p>

                    <h3 className="mt-5 mb-3">Real Estate</h3>

                    <p>
                      We are looking to enter the housing market, ditch rent, and start building equity. 
                    </p>

                    <div className="mt-3 money-label-font-large">Paid off House <span className="float-end">0%</span></div>
                    <ProgressBar percent={5}/>

                    <p className="mt-4">
                      Our current plan is to continue saving and pay cash for a rental. Hopefully our savings timeline and the housing market timelines match up well for us in about 8 to 10 years.
                    </p>

                    <div className="mt-3 money-label-font-large">Rental Properties <span className="float-end">0%</span></div>
                    <ProgressBar percent={5}/>


                    <h2 className="mt-5 mb-4">Simulation Software</h2>

                    <p className="mt-4">
                      I love excel, but its time to evolve...
                    </p>

                    <p className="mt-4">
                      I hope to finish a transaction based personal finance simulation app by the end of this year. 
                    </p>

                    <p className="mt-4">
                      The engine and half the UI is done. Working on fine tuning this financial education machine! 
                    </p>
                    
                    <p className="mt-4">The goals of this software:</p>

                    <ul>
                        <li>Validate financial advice on social media!</li>
                        <li>Track cash flow at the transaction level</li>
                        <li>Allow comparisons across multiple simulations</li>
                        <li>Allow users to build intuition around personal finance</li>
                    </ul>

                    <p>If you would like to be a part of the beta, contact me at <a href="mailto:crimsondose@gmail.com?subject=Interested in Simulation Software Updates" className="color-secondary">crimsondose@gmail.com</a></p>

                </div>
            </div>
        </div>

      </main>

      <div className="footer">

        <div className="container">
          <div className="row">

            <div className="col text-center">
                            
              <h2 className=" mb-3">What's Next?</h2>
                                      
              <div className="mt-4 mb-4 text-center">
                I am trying to surround myself with quality people and information. Let's connect!
              </div>

              <div className="col-xl-2 offset-xl-5 col-2 offset-5 text-center mb-5">
                <Twitter />
              </div>

            </div>

          </div>
        </div>
      </div>

      <FooterTrigger />

    </div>
  )
}

export default IndexPage
