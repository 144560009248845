import React from 'react';
import classnames from 'classnames';

interface ICDLogoProps {
  children?: React.ReactNode;
  className?: string;
}

import './CDLogo.scss';
const CDLogo: React.FC<ICDLogoProps> = ({className}) => {

  return (
    <a href="https://twitter.com/crimsondose" target="_blank">
        <svg style={{width: "75%"}} className={classnames('cd-logo', className)} viewBox="0 0 400 200">
            <path d="M 300 0 A 100 100 0 0 1 300 200 L 212.5 200 L 212.5 0 L 200 0 Z"></path>
            <path d="M 187.5 0 L 100 0 A 100 100 0 1 0 100 200 L 187.5 200 L 187.5 150 L 100 150 A 50 50 0 0 1 100 50 L 187.5 50 L 187.5 0 Z"></path>
        </svg>
    </a>
  );
}

export default CDLogo;



