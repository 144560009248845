import React from 'react';
import { motion } from "framer-motion"
import classnames from 'classnames';

interface IProgressBarProps {
  children?: React.ReactNode;
  className?: string;
  percent: number;
}

import './ProgressBar.scss';
const ProgressBar: React.FC<IProgressBarProps> = ({className, percent}) => {

  return (
    <div className={classnames('app-progress-bar', className)}>
        <motion.div
            className="app-progress-bar-progress"
            initial={{ x: `-75%`, width: "100%" }}
            whileInView={{ x: `-${100 - percent}%` }}
            viewport={{ once: true }}
            transition={{ delay: 0.25, duration: 1.5, type: "tween" }}
        >

            <span className="app-progress-bar-handle"></span>
            
        </motion.div>
    </div>
  );
}

export default ProgressBar;

