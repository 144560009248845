import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface IFootTriggerProps {
  children?: React.ReactNode;
}

import './FooterTrigger.scss';
const FooterTrigger: React.FC<IFootTriggerProps> = ({ children }) => {

    const { ref, inView, entry } = useInView({
        threshold: 0
    });

    useEffect(() => {
        if (inView) {
            document.getElementsByClassName("content-container")[0].classList.add("footer-focussed");
        } else {
            document.getElementsByClassName("content-container")[0].classList.remove("footer-focussed");
        }
    }, [inView]);

    return (
        <div className="footer-trigger" ref={ref}></div>
    );
}

export default FooterTrigger;

